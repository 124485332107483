@tailwind base;
@tailwind components;
@tailwind utilities;

.swiper-wrapper {
  @apply !pt-9;
}

.swiper-button-next {
  @apply !right-0 !top-0 !w-6 !h-6 !mt-0 bg-arrow-right;
}

.swiper-button-next::after {
  @apply hidden;
}

.swiper-button-prev {
  @apply !inset-unset !right-12 !top-0 !w-6 !h-6 !mt-0 bg-arrow-left;
}

.swiper-button-prev::after {
  @apply hidden;
}
