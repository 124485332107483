@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

/* start: DatePicker required styles */
.react-datepicker__input-container input {
  @apply relative rounded-md w-full text-gray-700 text-sm border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500;
}

.react-datepicker__tab-loop {
  @apply absolute;
}

.react-datepicker-popper {
  @apply absolute rounded-md top-1 z-10 left-0 w-72 text-sm transform-none bg-white shadow px-3 py-2 !important;
}

.react-datepicker__month-container {
  @apply flex flex-col;
}

.react-datepicker__month {
  @apply flex flex-col;
}

.react-datepicker__current-month {
  @apply ml-2.5 text-lg font-semibold text-gray-700;
}

.react-datepicker__week {
  @apply flex justify-around;
}

.react-datepicker__day-names {
  @apply flex justify-around text-gray-700 font-medium text-center text-xs;
}

.react-datepicker__day-name {
  @apply w-8 h-8 flex items-center justify-center py-1 rounded-full;
}

.react-datepicker__navigation {
  @apply absolute top-2;
}

.react-datepicker__navigation--previous {
  @apply right-12 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200 focus:outline-none;
}

.react-datepicker__navigation--next {
  @apply right-4 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200 focus:outline-none;
}

.react-datepicker__day {
  @apply mb-1 w-8 h-8 flex items-center justify-center py-1 text-sm leading-loose transition text-gray-700 rounded;
}

.react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-50 hover:bg-transparent;
}

.react-datepicker__day--outside-month {
  @apply text-gray-400;
}

.react-datepicker__day--selected {
  @apply bg-purple-500 text-white rounded-full;
}
/* end: DatePicker required styles */

/* start: Headwayapp required styles */
#HW_badge_cont {
  @apply absolute h-7 w-7 z-50;
}

.HW_badge.HW_visible {
  @apply left-auto top-0 -right-[2px] xl:right-0;
}

.HW_updates.is-collapsed .HW_badge.HW_visible {
  @apply -right-[2px];
}

.HW_softHidden {
  @apply invisible;
}

/* end: Headwayapp required styles */

/* date range picker style start */

.date-range-picker {
  @apply text-base;
}

.date-range-picker .react-datepicker__day--in-range {
  @apply rounded-none;
}

.date-range-picker .react-datepicker__day--in-range {
  @apply bg-blue-200 text-off-black font-semibold;
}

.date-range-picker .react-datepicker__day--in-selecting-range {
  @apply bg-blue-200 text-off-black font-semibold rounded-none;
}

.date-range-picker .react-datepicker__day--range-start {
  @apply bg-blue-600 text-white rounded-l-full;
}

.date-range-picker .react-datepicker__day--selected {
  @apply bg-blue-600 text-white;
}

.date-range-picker .react-datepicker__day--range-end {
  @apply bg-blue-600 text-white rounded-r-full;
}

.date-range-picker .react-datepicker__month-container:nth-child(odd) {
  @apply pl-4;
}

.date-range-picker .react-datepicker__day--outside-month {
  visibility: hidden;
}

/* date range picker style end */

/* custom progress style start */

progress::-webkit-progress-bar {
  @apply bg-gray-200;
}

progress::-webkit-progress-value {
  @apply bg-blue-600;
}
progress::-moz-progress-bar {
  @apply bg-blue-600;
}

/* custom progress style end */

/* custom meter style start */

meter::-webkit-meter-bar {
  @apply bg-gray-200;
}

meter::-webkit-meter-optimum-value {
  @apply bg-blue-600 duration-500 ease-in-out;
}

meter::-moz-meter-bar {
  @apply bg-gray-200;
}

meter::-moz-meter-optimum {
  @apply bg-blue-600 duration-500 ease-in-out;
}

/* custom meter style end */

/* custom range input thumb style start */

.price-range-input::-webkit-slider-thumb {
  @apply appearance-none w-8 h-8 -mt-2 rounded-2xl border-white border-2 border-solid bg-blue-600 cursor-pointer;
}

.price-range-input::-moz-range-thumb {
  @apply w-6 h-6 bg-blue-600 rounded-xl cursor-pointer;
}

.price-range-input::-webkit-slider-runnable-track {
  border-radius: 0.5rem;
  height: 1rem;
  background: linear-gradient(
    to right,
    #2563eb 0%,
    #2563eb var(--volume-progress),
    transparent var(--volume-progress),
    transparent
  );
}

.price-range-input::-moz-range-track {
  border-radius: 0.5rem;
  height: 1rem;
  background: linear-gradient(
    to right,
    #2563eb 0%,
    #2563eb var(--volume-progress),
    transparent var(--volume-progress),
    transparent
  );
}

/* custom range input thumb style end */

/* custom storybook overflow styles */
.sb-main-padded.sb-show-main,
.sb-show-main.sb-main-fullscreen {
  overflow-y: auto;
}

/* start: react-mentions required styles */

.react-mentions--multiLine {
  @apply flex flex-col justify-start items-start flex-grow;
}

.react-mentions__control {
  @apply border border-gray-300 rounded-md w-full bg-white transition-all ease-in-out duration-200 overflow-y-auto;
  min-height: 56px;
  max-height: 400px;
}

.react-mentions__control:focus-within {
  @apply outline-none border-blue-400 ring-1 ring-blue-600 pr-0;
}

.react-mentions__input {
  @apply shadow-none outline-none border-none rounded-md transition-all ease-in-out duration-200 text-sm text-gray-900 leading-5 font-normal tracking-wider placeholder-gray-400 hover:placeholder-gray-500 focus:ring-0 overflow-y-auto !important;
}

.react-mentions__input:focus {
  @apply outline-none !important;
  width: 100% !important;
}

.react-mentions__suggestions {
  @apply mt-8 !important;
}

.react-mentions__suggestions__item {
  @apply px-1 text-sm;
}

.react-mentions__suggestions__item--focused {
  @apply px-1 text-sm bg-gray-200;
}

/* end: react-mentions required styles */

/* the container for the inkeep button causes a white bar at the bottom
   of the screen for our layout pages */
div[id^='inkeep-shadow\:'] {
  height: 0;
}

/* start: rrwebPlayer controls required styles */
input[type='range'] {
  @apply appearance-none bg-gray-300 rounded-lg cursor-pointer h-[7px] w-full;
}

input[type='range']::-webkit-slider-thumb {
  @apply appearance-none bg-blue-400 border-[3px] border-white border-solid h-5 w-5 rounded-full cursor-pointer;
}

input[type='range']:focus {
  @apply outline-none;
}

input[type='range']:focus::-webkit-slider-thumb {
  @apply outline-2 outline outline-blue-600;
}

input[type='range']:focus::-moz-range-thumb {
  @apply outline-2 outline outline-blue-600;
}

/* end: rrwebPlayer controls required styles */

/* start: button-dropdown-no-icon */

.button-dropdown-no-icon > span {
  @apply mr-0;
}

.button-dropdown-no-icon > svg {
  @apply hidden;
}

/* end: button-dropdown-no-icon */
