@tailwind base;
@tailwind components;
@tailwind utilities;

.editor {
  @apply bg-white min-h-100 rounded text-sm font-highlight font-medium;
}

.editor #codeArea {
  @apply outline-none !pl-14;
}

.editor pre {
  @apply !pl-14;
}

.editor .editorLineNumber {
  @apply absolute left-0 text-gray-500 text-right w-10 text-sm;
}

.editor .token.keyword {
  color: #003cb0 !important;
}
.editor .token.number {
  color: #26aaa7 !important;
}
.editor .token.operator {
  color: #000000 !important;
}

#metrics-api-editor .editor {
  background-color: #343f55;
  color: #b8c4db;
  border: 1px solid #5e6d8c;
}

#metrics-api-editor .editor .token.comment {
  color: #b8c4db;
}

#analyze-editor .editor {
  @apply min-h-300 border border-gray-200;
}
